import React from "react";

function Marketing(props) {
  return (
    <div>
      알바선물 및 제휴서비스의 이벤트ㆍ혜택 등의 정보 발송을 위해 알바선물
      아이디(식별값 포함), 이름, 휴대전화번호(알림톡 또는 문자), 주소를
      수집합니다.
      <br />
      <br />
      알바선물 아이디(식별값 포함), 이름, 휴대전화번호(알림톡 또는 문자), 주소는
      알바선물 서비스 제공을 위한 필수 수집 항목으로서, 알바선물 회원 가입 기간
      동안 보관하나, 이벤트 혜택 정보 수신 동의를 철회하시면 본 목적으로의
      개인정보 처리는 중지됩니다.
      <br />
      <br />
      정보주체는 개인정보 수집 및 이용동의를 거부하실 수 있으며, 미동의 시에도
      서비스 이용은 가능합니다.
    </div>
  );
}

export default Marketing;
