export const category = [
  {
    category1Seq: 1,
    category1Name: "커피/음료",
  },
  {
    category1Seq: 2,
    category1Name: "베이커리/도넛",
  },
  {
    category1Seq: 3,
    category1Name: "아이스크림",
  },
  {
    category1Seq: 4,
    category1Name: "편의점",
  },
  {
    category1Seq: 5,
    category1Name: "피자/버거/치킨",
  },
  {
    category1Seq: 6,
    category1Name: "외식/분식/배달",
  },
  {
    category1Seq: 7,
    category1Name: "영화/음악/도서",
  },
  {
    category1Seq: 9,
    category1Name: "뷰티/헤어/바디",
  },
  {
    category1Seq: 10,
    category1Name: "출산/생활/통신",
  },
  {
    category1Seq: "etc",
    category1Name: "기타상품",
  },
];
